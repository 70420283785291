import { CCol, CContainer, CImage, CRow } from "@coreui/react";
import React from "react";
import useApiDataStore from "../../../useApiDataStore";

const Banner = ({ header }) => {
  const { theme } = useApiDataStore();

  return (
    <div>
      <CRow>
        <CImage className="p-0 w-100" src={`data:image/jpeg;base64,${theme.banner}`} style={{ borderBottomRightRadius: "1rem", borderBottomLeftRadius: "1rem", boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)" }} />
      </CRow>
      <CRow className="px-2 pb-2" style={{ marginTop: "-100px" }}>
        <CCol
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-100px",
          }}
        >
          <CContainer className="g-0 text-center">
            {theme.showLogoInMenu && <CImage
              src={`data:image/jpeg;base64,${theme.logo}`}
              align="center"
              style={{
                background: "white",
                borderRadius: "50%",
                height: "20vh",
                aspectRatio: "1/1",
              }}
            />}
            <h3>{header}</h3>
          </CContainer>
        </CCol>
      </CRow>
    </div>
  );
};

export default React.memo(Banner);
