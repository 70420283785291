import { CCard, CCardImage, CCardImageOverlay, CCardTitle, CCol, CContainer, CImage, CRow } from "@coreui/react";
import React from "react";
import ReactGA from 'react-ga';
import { Link, useParams } from "react-router-dom";
import forknifeLogo from "../../assets/images/forknife.png";
import useApiDataStore from "../../useApiDataStore";
import { useEffect } from "react";
import ContentLoader from "react-content-loader";
import { logPageView } from "../../analytics";

const Start2 = () => {
    const { supercategories, theme, info, getEateryInfo } = useApiDataStore();
    const { eateryId } = useParams();

    // Google Analytics
    useEffect(() => {
        logPageView("start", eateryId);
    }, []);

    useEffect(() => {
        const shouldAddFontLink = true;

        if (shouldAddFontLink) {
            let linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,800&display=swap';

            document.head.appendChild(linkElement);

            return () => {
                document.head.removeChild(linkElement);
            };
        }
    }, []);

    const imageStyle = {
        width: '50%',
        aspectRatio: '1/1',
        //height: '100%',
        objectFit: 'cover',
    };

    const cellStyle = {
        position: 'relative',
        overflow: 'hidden',
        height: '25vh',
    };

    const cardImageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))'
    };

    const gradientStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0))',
    };

    const overlayStyle = {
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
    };

    const textStyle = {
        color: 'white',
        fontFamily: 'Playfair Display, serif',
        fontWeight: 800,
        fontSize: '2em'
    };

    useEffect(() => {
        if (!theme) {
            getEateryInfo(eateryId);
        }
    });

    const w = window.innerWidth;
    const h = window.innerHeight;

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (!theme) {
        return (
            <CContainer sm className="g-0" style={{ height: '100%', width: '100%', display: 'block' }}>
                <CCol style={{ height: '85%', width: '100%' }} className="p-2 pt-5">
                    <ContentLoader
                        speed={2}
                        width={w}
                        height={h}
                        viewBox={`0 0 ${w} ${h}`}
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        {/* Placeholder for image */}
                        <circle cx={window.innerWidth / 2} cy="110" r="90" />

                        {/* Placeholder for title */}
                        <rect x="20" y="250" rx="3" ry="3" width="80%" height="20" />

                        {/* Placeholder for description */}
                        <rect x="20" y="280" rx="3" ry="3" width="350" height="10" />

                        {/* Placeholder for button */}
                        <rect x="20" y="300" rx="3" ry="3" width="100" height="30" />
                    </ContentLoader>
                </CCol>
                <CCol style={{ height: '10%' }} className="m-2 p-2 d-flex flex-row justify-content-end align-items-center">
                    <ContentLoader
                        speed={2}
                        width={400}
                        height={50}
                        viewBox="0 0 400 50"
                        backgroundColor="#f3f3f3"
                        foregroundColor="#ecebeb"
                    >
                        {/* Placeholder for powered by text */}
                        <rect x="20" y="10" rx="3" ry="3" width="250" height="10" />

                        {/* Placeholder for flag emoji */}
                        <rect x="300" y="10" rx="3" ry="3" width="30" height="30" />
                    </ContentLoader>
                </CCol>
            </CContainer>
        );
    }

    return (
        <CContainer
            sm
            className="g-0"
            style={{ height: '100%', width: '100%', backgroundColor: theme.backgroundColor, color: theme.textColor, display: "block" }}
        >
            <CCol
                style={{ height: "85%", width: '100%' }}
                className="d-flex flex-column justify-content-center align-items-center p-2 pt-1"
            >
                <CImage
                    src={`data:image/jpeg;base64,${theme.logo}`}
                    style={imageStyle}
                />

                <CContainer>
                    <CRow>
                        <CCol xs="6" className="mb-4">
                            <div style={cellStyle}>
                                <Link to="menu" preventScrollReset={true} state={{ category: supercategories[0].categoryTag }} className="w-100" style={{ color: theme.primaryColor }}>
                                    <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                        <CCardImage src={`data:image/jpeg;base64,${supercategories[0].displayImage}`}
                                            alt="Image 4"
                                            className="w-100"
                                            style={cardImageStyle}
                                        />
                                        <div style={gradientStyle}></div>
                                        <CCardImageOverlay style={overlayStyle}>
                                            <CCardTitle style={textStyle}>{supercategories[0].displayName}</CCardTitle>
                                        </CCardImageOverlay>
                                    </CCard>
                                </Link>
                            </div>
                        </CCol>
                        <CCol xs="6" className="mb-4">
                            <div style={cellStyle}>
                                <Link to="menu" preventScrollReset={true} state={{ category: supercategories[1].categoryTag }} className="w-100" style={{ color: theme.primaryColor }}>
                                    <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                        <CCardImage src={`data:image/jpeg;base64,${supercategories[1].displayImage}`}
                                            alt="Image 4"
                                            className="w-100"
                                            style={cardImageStyle}
                                        />

                                        <div style={gradientStyle}></div>
                                        <CCardImageOverlay style={overlayStyle}>
                                            <CCardTitle style={textStyle}>{supercategories[1].displayName}</CCardTitle>
                                        </CCardImageOverlay>
                                    </CCard>
                                </Link>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol xs="6" className="mb-4">
                            <div style={cellStyle}>
                            <Link to="menu" preventScrollReset={true} state={{ category: supercategories[2].categoryTag }} className="w-100" style={{ color: theme.primaryColor }}>
                                
                                <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                    <CCardImage src={`data:image/jpeg;base64,${supercategories[2].displayImage}`}
                                        alt="Image 4"
                                        className="w-100"
                                        style={cardImageStyle}
                                    />

                                    <div style={gradientStyle}></div>
                                    <CCardImageOverlay style={overlayStyle}>
                                        <CCardTitle style={textStyle}>{supercategories[2].displayName}</CCardTitle>
                                    </CCardImageOverlay>
                                </CCard>
                                </Link>
                            </div>
                        </CCol>
                        <CCol xs="6" className="mb-4">
                            <div style={cellStyle}>
                            <Link to="menu" preventScrollReset={true} state={{ category: supercategories[3].categoryTag }} className="w-100" style={{ color: theme.primaryColor }}>
                                
                                <CCard className="w-100 h-100 mb-3 bg-dark text-white">
                                    <CCardImage src={`data:image/jpeg;base64,${supercategories[3].displayImage}`}
                                        alt="Image 4"
                                        className="w-100"
                                        style={cardImageStyle}
                                    />

                                    <div style={gradientStyle}></div>
                                    <CCardImageOverlay style={overlayStyle}>
                                        <CCardTitle style={textStyle}>{supercategories[3].displayName}</CCardTitle>
                                    </CCardImageOverlay>
                                </CCard>
                                </Link>
                            </div>
                        </CCol>
                    </CRow>
                </CContainer>
            </CCol>
            <CCol
                style={{ height: "10%" }}
                className="m-2 p-2 d-flex flex-row justify-content-end align-items-center"
            >
                <div style={{ flex: 1 }}>
                    Powered by&nbsp;&nbsp;&nbsp;
                    <CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} />
                    <br />
                    {info.address.country === "Algeria" && <span>
                        Made in 🇩🇿 with ❤️
                    </span>}
                </div>
            </CCol>
        </CContainer>
    );
};

export default React.memo(Start2);
