import { create } from "zustand";
import { config } from './config.js';

var eateryid;

const useApiDataStore = create((set) => ({
  info: null,
  menu: null,
  theme: null,
  links: {},
  categories: null,
  supercategories: null,
  order: [],

  getEateryInfo: async (eateryId) => {
    try {
      const response = await fetch(`${config.API_URL}/api/eatery/${eateryId}`);
      const data = await response.json();

      eateryid = data.eatery.id;
      document.title = data.eatery.name;

      set({
        info: data.eatery,
        menu: data.menu,
        categories: data.categories,
        supercategories: data.supercategories,
        theme: data.eatery.theme,
        links: data.eatery.links,
      });

    } catch (error) {
      console.error("Une erreur s'est produite lors de la connexion au serveur.", error);
      throw error;
    }
  },

  getOrder: () => {
    return set().order;
  },

  setOrder: (newOrder) => {
    set({ order: newOrder });
  },

  clearData: () => {
    set({
      info: null,
      menu: null,
      theme: null,
      links: null,
      categories: null,
      supercategories: null,
      order: null,
    });
  },

  submitRating: async (rating, message) => {
    const postData = {
      rating: rating,
      message: message,
      eateryId: eateryid,
      device: `${navigator.userAgent} - ${navigator.platform}`,
    };

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    };

    const response = await fetch(`${config.API_URL}/api/ratings`, requestOptions);
    
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
  }
}));

export default useApiDataStore;
