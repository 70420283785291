import {
  cibFacebook,
  cibInstagram,
  cibTiktok,
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCol,
  CContainer,
} from "@coreui/react";
import React from "react";
import { Link } from "react-router-dom";
import Banner from "../menu/components/Banner";

const ContactLinks = () => {
  return (
    <CContainer
      sm
      className="g-0 d-flex flex-column"
      style={{ paddingBottom: '10vh', overflowY: "scroll", overflowX: "hidden" }}
    >
      <Banner header='Suivez-nous!' />
      <CCol className="d-flex flex-column justify-content-evenly h-100 mx-5">
        <Link to="#">
          <CButton
            color="light"
            size="lg"
            className="w-100 bg-info text-white text-uppercase font-weight-bold"
            style={{ background: "", fontWeight: "bold", fontSize: "small" }}
          >
            <CIcon icon={cibFacebook} /> Facebook
          </CButton>
        </Link>
        <Link to="#">
          <CButton
            color="light"
            size="lg"
            className="w-100 bg-light text-white text-uppercase font-weight-bold"
            style={{
              background:
                "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
              fontWeight: "bold",
              fontSize: "small",
            }}
          >
            <CIcon icon={cibInstagram} /> Instagram
          </CButton>
        </Link>
        <Link to="#">
          <CButton
            color="light"
            size="lg"
            className="w-100 text-black text-uppercase font-weight-bold"
            style={{
              background: "#00eae7",
              fontWeight: "bold",
              fontSize: "small",
            }}
          >
            <CIcon icon={cibTiktok} /> Tiktok
          </CButton>
        </Link>
      </CCol>
    </CContainer>
  );
};

export default React.memo(ContactLinks);
