import { CContainer } from "@coreui/react";
import React, { useEffect, useState } from "react";
import useApiDataStore from "../../useApiDataStore";
import Banner from "./components/Banner";
import CategoriesNav from "./components/CategoriesNav";
import FoodItemList from "./components/FoodItemList";
import MenuLoader from "./components/MenuLoader";
import { useLocation, useParams } from "react-router-dom";
import Headroom from "react-headroom";
import { logPageView } from "../../analytics";

const Menu = () => {
  const { eateryId } = useParams();
  const { info, theme, menu, categories, getEateryInfo } = useApiDataStore();
  let { state } = useLocation();
  const [selectedCategory, setSelectedCategory] = useState(state?.category || null);  

  // Google Analytics
  useEffect(() => {
    logPageView("menu", eateryId);
  }, []);

  useEffect(() => {
    if (!info || !menu || !categories) {
      getEateryInfo(eateryId);
    }
  }, [info, theme, menu, categories, getEateryInfo]);

  return (
    menu ? (
      <CContainer id='headroom-parent' style={{ backgroundColor: theme.backgroundColor, paddingBottom: '10vh', overflowY: "scroll", overflowX: "hidden" }}>
        <Banner />
        <Headroom pinStart={200}>
          <CategoriesNav categories={categories} activeCategory={selectedCategory} />
        </Headroom>
        <FoodItemList menuData={menu} categories={categories} currency={info.currency} setSelectedCategory={setSelectedCategory} />
      </CContainer>
    ) : (
      <MenuLoader />
    )
  );
};

export default React.memo(Menu);
