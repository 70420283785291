import { CContainer } from "@coreui/react";
import React, { useEffect } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import "./App.css";
import BottomNavbar from "./components/BottomNavbar";
import "./scss/style.scss";
import useApiDataStore from "./useApiDataStore";
import { initGA, logPageView } from "./analytics";

function App() {
  const location = useLocation();
  const { eateryId } = useParams();
  const { info, theme, getEateryInfo } = useApiDataStore();

  useEffect(() => {
    if (!theme) {
      getEateryInfo(eateryId);
    }
  }, [info, theme, getEateryInfo]);

  const pattern = /^\/[\w]+\/$/;
  const isStartRoute = pattern.test(location.pathname);

  return (
    <CContainer
      id="main-container"
      className="g-0 mb-3"
      style={{ backgroundColor: theme?.backgroundColor ? theme.backgroundColor : 'transparent', position: 'absolute', minHeight: '100vh' }}
    >
      <Outlet />
      {!isStartRoute && <BottomNavbar />}
    </CContainer>
  );
}

export default App;