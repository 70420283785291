import { CButton, CCol, CContainer, CImage, CRow } from "@coreui/react";
import { Link, useRouteError } from "react-router-dom";
import forknifeLogo from "../assets/images/forknife.png";
import errorDoodle from "../assets/images/error.svg";

export default function ErrorPage() {
  const error = useRouteError();
  console.error(error);

  return (
    <CContainer
      className="d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <CCol
        className="d-flex flex-column justify-content-evenly"
        style={{ height: "80vh" }}
      >
        <CRow className="w-100 d-flex justify-content-center">
          <CCol className="w-100 d-flex justify-content-center">
            <CImage
              src={forknifeLogo}
            //style={{ height: 47.84, width: 235.48, aspectRatio: "1/1" }}
            />
          </CCol>
        </CRow>
        <h2>Hmm... Il semblerait que cette page n’existe pas.</h2>
        <CImage
          src={errorDoodle}
          align="center" /* style={{ minHeight: '50vh', maxWidth: '100%', objectFit: 'cover' }} */
        />
        <Link to="../menu">
          <CButton
            color="light"
            size="lg"
            className="w-100 bg-light text-black text-uppercase font-weight-bold"
            style={{ fontWeight: "bold", fontSize: "small" }}
          >
            Retour au menu
          </CButton>
        </Link>
      </CCol>
    </CContainer>
  );
}
