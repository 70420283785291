import {
    cilDescription,
    cilDinner,
    cilStar
} from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CBadge, CCol, CNav, CNavItem, CNavLink, CRow } from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useApiDataStore from "../useApiDataStore";

const BottomNavbar = () => {
    const [activeKey, setActiveKey] = useState(1);
    const { theme, getEateryInfo, order } = useApiDataStore();
    const { eateryId } = useParams();

    useEffect(() => {
        if (!theme) {
            getEateryInfo(eateryId);
        }
    }, [theme, getEateryInfo]);

    return (
        <CNav
            className="d-flex justify-content-evenly align-items-center rounded-top px-3"
            style={{
                height: "10vh",
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 10,
                width: '100%',
                background: theme.activeColor,
                color: theme.activeTextColor,
                zIndex: 100,
                borderTopRightRadius: "1rem", borderTopLeftRadius: "1rem",
                boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.3)",
            }}
        >
            <CNavItem role="presentation">
                <CNavLink
                    href="#"
                    active={activeKey === 1}
                    component="button"
                    role="tab"
                    aria-controls="home-tab-pane"
                    aria-selected={activeKey === 1}
                    style={{ color: theme.secondaryColor }}
                    onClick={() => setActiveKey(1)}
                >
                    <Link to="menu" className="text-decoration-none">
                        <CCol className="g-0 justify-content-center align-items-center" style={{ color: theme.secondaryColor }}>
                            <CRow className="g-0 justify-content-center align-items-center black">
                                {/* <CIcon icon={cilDescription} size="xl" /> */}
                                <CIcon icon={cilDinner} size="xl" />
                                
                            </CRow>
                            <CRow>
                                <small style={{ fontSize: "small" }}>Menu</small>
                            </CRow>
                        </CCol>
                    </Link>
                </CNavLink>
            </CNavItem>
            {theme.showRecapScreen && <CNavItem role="presentation">
                <CNavLink
                    href="#"
                    active={activeKey === 2}
                    component="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected={activeKey === 2}
                    onClick={() => setActiveKey(2)}
                    className="position-relative"
                >
                    <Link to="order" className="position-relative text-decoration-none">
                        <CCol className="g-0" style={{ color: theme.secondaryColor }}>
                            <CRow className="g-0 justify-content-center align-items-center black">
                                <div style={{ width: 'fit-content', maxWidth: 'fit-content', }}><CIcon icon={cilDescription} size="xl" />{order && Object.keys(order).length > 0 && (
                                    <CBadge position="top" shape="rounded-pill" style={{ backgroundColor: theme.activeColor, color: theme.activeTextColor, left: '30% !important' }}>
                                        {Object.keys(order).length}
                                    </CBadge>
                                )}</div>
                            </CRow>
                            <CRow>
                                <small style={{ fontSize: "small" }}>Récap</small>
                            </CRow>
                        </CCol>
                    </Link>
                </CNavLink>
            </CNavItem>}
            <CNavItem role="presentation">
                <CNavLink
                    href="#"
                    active={activeKey === 3}
                    component="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected={activeKey === 3}
                    onClick={() => setActiveKey(3)}
                >
                    <Link to="rate" className="text-decoration-none">
                        <CCol className="g-0 justify-content-center align-items-center" style={{ color: theme.secondaryColor }}>
                            <CRow className="g-0 justify-content-center align-items-center black">
                                <CIcon icon={cilStar} size="xl" />
                            </CRow>
                            <CRow>
                                <small style={{ fontSize: "small" }}>Avis</small>
                            </CRow>
                        </CCol>
                    </Link>
                </CNavLink>
            </CNavItem>
            {/* <CNavItem role="presentation">
                <CNavLink
                    href="#"
                    active={activeKey === 4}
                    component="button"
                    role="tab"
                    aria-controls="contact-tab-pane"
                    aria-selected={activeKey === 4}
                    onClick={() => setActiveKey(4)}
                >
                    <Link to="settings" className="text-decoration-none">
                        <CCol className="g-0 justify-content-center align-items-center" style={{ color: theme.secondaryColor }}>
                            <CRow className="g-0 justify-content-center align-items-center black">
                                <CIcon icon={cilCog} size="xl" />
                            </CRow>
                            <CRow>
                                <small style={{ fontSize: "small" }}>Réglages</small>
                            </CRow>
                        </CCol>
                    </Link>
                </CNavLink>
            </CNavItem> */}
        </CNav>
    );
};

export default React.memo(BottomNavbar);
