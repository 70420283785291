import { cilArrowLeft } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CButtonGroup,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CFormCheck,
  CImage,
  CListGroup,
  CListGroupItem,
  CRow,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import useApiDataStore from "../../useApiDataStore";
import "./FoodItemDetails.css";
import { logPageView } from "../../analytics";

const FoodItemDetails = () => {
  const { eateryId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { theme, info, order, setOrder } = useApiDataStore();
  const { id, name, description, price, photo, video, extras, popular, outOfStock, tags } =
    location.state || {};

  // Google Analytics
  useEffect(() => {
    logPageView("details", eateryId, id);
  }, []);  

  const [quantity, setQuantity] = useState(1);
  const plus = () => {
    setQuantity(Math.min(quantity + 1, 10));
  };
  const minus = () => {
    setQuantity(Math.max(quantity - 1, 1));
  };

  const [selectedExtras, setSelectedExtras] = useState({});

  const handleCheckboxChange = (extra, isChecked) => {
    setSelectedExtras(prevExtras => {
      if (isChecked) {
        return {
          ...prevExtras,
          [extra]: extras[extra],
        };
      } else {
        const updatedExtras = { ...prevExtras };
        delete updatedExtras[extra];
        return updatedExtras;
      }
    });
  };


  const calculateTotalPrice = () => {
    let extrasTotal = Object.values(selectedExtras).reduce((acc, curr) => acc + curr, 0);
    return (price + extrasTotal) * quantity;
  };

  useEffect(() => { }, [
    name,
    description,
    price,
    photo,
    popular,
    outOfStock,
    tags,
  ]);

  const [toast, addToast] = useState(0);
  const toaster = useRef();
  const toastOk = (
    <CToast
      autohide={true}
      visible={true}
      className="p-3 mb-5 w-100 align-items-center"
      style={{ backgroundColor: theme.successColor, color: theme.activeTextColor }}
    >
      <div className="d-flex">
        <CToastBody style={{ fontSize: "large" }}>
          {quantity} {name}
          {quantity > 1 ? "s" : ""} {quantity > 1 ? "ont" : "a"} bien été noté(e)
          {quantity > 1 ? "s" : ""} dans votre récapitulatif!
        </CToastBody>
        <CToastClose className="me-2 m-auto" color={theme.activeTextColor} />
      </div>
    </CToast>
  );

  function addToCurrentOrder(quantity) {
    const itemIndex = order.findIndex(item => {
      if (item.id === id) {
        const selectedExtrasKeys = Object.keys(selectedExtras);
        const itemExtrasKeys = Object.keys(item.extras);
        return selectedExtrasKeys.every(key => itemExtrasKeys.includes(key)) && itemExtrasKeys.every(key => selectedExtrasKeys.includes(key));
      }
      return false;
    });

    if (itemIndex !== -1) {
      order[itemIndex].quantity += quantity;
    } else {
      const newItem = {
        id: id,
        quantity: quantity,
        extras: selectedExtras
      };
      order.push(newItem);
    }

    setOrder([...order]);

    // reset extras and counter
    setQuantity(1);
    setSelectedExtras({});

    addToast(toastOk);
  }

  return (
    <CContainer
      className="g-0 d-flex flex-column"
      style={{
        height: "100vh",
        paddingBottom: "10vh",
        overflowY: "scroll",
        overflowX: "hidden",
      }}
    >
      <CButton
        size="lg"
        className={`rounded-circle border back-button d-block d-sm-none`}
        color="white"
        onClick={() => navigate(-1)}
      >
        <CIcon
          size="xxl"
          icon={cilArrowLeft}
          style={{ height: "100%", width: "100%" }}
        />
      </CButton>
      {photo !== null && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            aspectRatio: "1/1",
            flexShrink: 0,
            overflow: "hidden",
          }}
        >
          <CImage
            className="zoom-in-out2"
            src={`data:image/jpeg;base64,${photo}`}
            align="center"
            style={{ maxWidth: "100%", objectFit: "cover", aspectRatio: '1/1' }}
          />
        </div>
      )}
      {photo === null && (
        <CImage className="p-0 w-100" src={`data:image/jpeg;base64,${theme.banner}`} />
      )}
      <CCard
        className="w-100 card-container flex-grow-1 p-2"
        style={{
          backgroundColor: theme.backgroundColor,
          color: theme.textColor,
          borderColor: theme.borderColor,
          boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.9)",
        }}
      >
        <CCardBody className="mt-3 d-flex flex-column">
          <CCardTitle className="mb-4">
            <CRow>
              <CCol xs={12}>
                {name} {tags && tags.map((tag) => tag.label)}
                {popular && (
                  <CButton
                    className="text-white p-1 rounded"
                    style={{
                      fontSize: 12,
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Populaire!
                  </CButton>
                )}
                {outOfStock && (<CButton className="bg-danger text-white mx-2 p-1 rounded" style={{
                  fontSize: 10,
                  fontWeight: "bold",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}>
                  Indisponible
                </CButton>)}
              </CCol>
            </CRow>
            <CRow><p className="text-muted">{price} {info.currency}</p></CRow>
          </CCardTitle>
          <div className="p-2 flex-grow-1">
            <CCardText>
              {description}
            </CCardText>
            <CListGroup flush>
              {
                Object.keys(extras).map((extra, index) => {
                  return (
                    <CListGroupItem key={index}>
                      <CFormCheck
                        id={extra}
                        label={`+ ${extra} - ${extras[extra]} ${info.currency}`}
                        checked={!!selectedExtras[extra]} // Reflects the checked state from the state variable
                        onChange={(e) => handleCheckboxChange(extra, e.target.checked)}
                      />
                    </CListGroupItem>
                  )
                })
              }
            </CListGroup>
          </div>
          <CRow>
            <CCol xs={5}>
              <CButtonGroup
                role="group"
                className="c-button-group mb-2"
                style={{
                  background: theme.activeColor,
                  borderColor: theme.borderColor,
                }}
              >
                <CButton
                  disabled={outOfStock === true}
                  variant="outline"
                  className="c-button"
                  style={{
                    borderRight: "none",
                    borderColor: theme.borderColor,
                    color: theme.activeTextColor,
                  }}
                  onClick={minus}
                >
                  -
                </CButton>
                <CButton
                  variant="outline"
                  disabled
                  className="c-button"
                  style={{
                    opacity: "initial",
                    borderLeft: "none",
                    borderRight: "none",
                    borderColor: theme.borderColor,
                    color: theme.activeTextColor,
                  }}
                >
                  {quantity}
                </CButton>
                <CButton
                  disabled={outOfStock === true}
                  variant="outline"
                  className="c-button"
                  style={{
                    borderLeft: "none",
                    borderColor: theme.borderColor,
                    color: theme.activeTextColor,
                  }}
                  onClick={plus}
                >
                  +
                </CButton>
              </CButtonGroup>
            </CCol>
            <CCol xs={7}>
              <CButton
                disabled={outOfStock === true}
                onClick={() => addToCurrentOrder(quantity)}
                style={{
                  background: theme.activeColor,
                  color: theme.activeTextColor,
                  borderColor: theme.borderColor,
                  width: "100%",
                  height: "100%",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <div style={{ fontSize: "small" }}>NOTER</div>
                <div style={{ fontSize: "small" }}>
                  {calculateTotalPrice()} {info.currency}
                </div>
              </CButton>
              <CToaster ref={toaster} push={toast} placement="bottom" />
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CContainer>
  );
};

export default React.memo(FoodItemDetails);
