import { CCol, CNav, CNavItem, CNavLink, CRow } from "@coreui/react";
import React, { useRef, useState } from "react";
import useApiDataStore from "../../../useApiDataStore";
import { useEffect } from "react";
import './CategoriesNav.css';
import { act } from "react-dom/test-utils";

const CategoriesNav = ({ categories, activeCategory }) => {
  const { theme } = useApiDataStore();

  const [selectedCategory, setSelectedCategory] = useState(activeCategory);

  useEffect(() => {
    setSelectedCategory(activeCategory);
  }, [activeCategory]);

  useEffect(() => {
    setTimeout(() => {
      handleCategoryClick(activeCategory)
    }, 400)
  }, [])

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);

    const anchorElement = document.getElementById(category);
    if (anchorElement) {
      anchorElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <CRow className="p-2 h-100" style={{ background: theme.backgroundColor }}>
      <CCol>
        <CNav id="h-nav" variant="pills" style={{ borderColor: theme.borderColor, backgroundColor: theme.backgroundColor, display: 'flex', scrollbarWidth: 'none', overflowX: 'scroll', flexWrap: 'nowrap', overflowY: 'hidden' }}>
          {categories.map((category) => (
            <CNavItem key={category.tag} className="px-1" style={{ display: 'flex', whiteSpace: 'nowrap !important', borderColor: theme.borderColor }}>
              <CNavLink
                className="rounded-pill"
                onClick={() => handleCategoryClick(category.tag)}
                style={{
                  backgroundColor: selectedCategory === (category.tag) ? theme.primaryColor : theme.backgroundColor,
                  color: selectedCategory === (category.tag) ? theme.backgroundColor : theme.primaryColor,
                  fontWeight: "bold",
                  display: 'flex',
                  whiteSpace: 'nowrap',
                  borderColor: theme.borderColor,
                  border: `solid 1px ${theme.borderColor}`
                }}
              >
                {category.shortName}
              </CNavLink>
            </CNavItem>
          ))}
        </CNav>
      </CCol>
    </CRow>
  );
};

export default React.memo(CategoriesNav);
