import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import App from "./App";
import { config } from './config.js';
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./scss/style.scss";
import ErrorPage from "./views/ErrorPage";
import ContactLinks from "./views/contactLinks/ContactLinks";
import FoodItemDetails from "./views/foodItemDetails/FoodItemDetails";
import Menu from "./views/menu/Menu";
import Order from "./views/order/Order";
import Rate from "./views/rate/Rate";
import Settings from "./views/settings/Settings";
import StartScreenPicker from "./views/start/StartScreenPicker.js";

var url = window.location.href;
var pathSegments = url.split('/');
var eateryId = pathSegments[3];

if (!eateryId) {
  eateryId = config.DEMO_EATERY_ID;
}

const initialPath = `/${eateryId}/`;
console.log(process.env.NODE_ENV, initialPath)

const router = createBrowserRouter([
  {
    path: "/:eateryId",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <StartScreenPicker />,
      },
      {
        path: "menu",
        element: <Menu />,
      },
      {
        path: "menu/item/:id",
        element: <FoodItemDetails />,
      },
      {
        path: "links",
        element: <ContactLinks />,
      },
      {
        path: "rate",
        element: <Rate />,
      },
      {
        path: "order",
        element: <Order />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      }
    ],
  },
])

router.navigate(initialPath);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
