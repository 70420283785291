import React, { useState } from "react";
import "./StarRating.css";


const StarRating = ({ rating, onRatingChange }) => {
    const [hover, setHover] = useState(0);

    const getMessage = () => {
        if (rating === 1) return 'Très mauvais';
        if (rating === 2) return 'Mauvais';
        if (rating === 3) return 'Médiocre';
        if (rating === 4) return 'Bien';
        if (rating === 5) return 'Très bien';
        return '';
    };

    return (
        <div className="star-rating d-flex justify-content-center align-items-center">
            {[...Array(5)].map((star, index) => {
                index += 1;
                return (
                    <button
                        type="button"
                        key={index}
                        className={index <= (hover || rating) ? 'on' : 'off'}
                        onClick={() => onRatingChange(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(rating)}
                    >
                        <span className="star fs-2">&#9733;</span>
                    </button>
                );
            })}
            <p className="m-0 d-flex justify-content-center align-items-center">{getMessage()}</p>
        </div>
    );
};


export default React.memo(StarRating);
