import { cibFacebook, cibInstagram, cibTiktok } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CContainer,
  CForm,
  CFormLabel,
  CFormTextarea,
  CRow,
  CToast,
  CToastBody,
  CToastClose,
  CToaster,
} from "@coreui/react";
import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import Banner from "../menu/components/Banner";
import StarRating from "./StarRating";
import useApiDataStore from "../../useApiDataStore";
import { useRef } from "react";
import { useEffect } from "react";
import { logPageView, trackButtonClick } from "../../analytics";

const Rate = () => {
  const { info, theme, links, submitRating } = useApiDataStore();
  const [rating, setRating] = useState(0);
  const [message, setMessage] = useState("");
  const [toast, addToast] = useState(0);
  const { eateryId } = useParams();

  const toaster = useRef();
  const toastOk = (
    <CToast
      autohide={true}
      visible={true}
      className="p-3 mb-5 w-100 align-items-center"
      style={{ backgroundColor: theme.successColor, color: theme.activeTextColor }}
    >
      <div className="d-flex">
        <CToastBody style={{ fontSize: "large" }}>
          Merci pour votre commentaire. 🙏
        </CToastBody>
        <CToastClose className="me-2 m-auto" color={theme.activeTextColor} />
      </div>
    </CToast>
  );


  const handleRatingChange = (newRating) => {
    setRating(newRating);
  };

  const handleMessageBoxChange = (event) => {
    setMessage(event.target.value);
  };

  function submit() {
    submitRating(rating, message)
    addToast(toastOk);
  }

  const track = (name) => {
    return () => {
      trackButtonClick(name);
    };
  };

  // Google Analytics
  useEffect(() => {
    logPageView("rating", eateryId);
  }, []);

  return (
    <CContainer
      style={{
        paddingBottom: "15vh",
        overflowY: "scroll",
        overflowX: "hidden",
        background: theme.backgroundColor,
        color: theme.textColor,
      }}
    >
      <Banner />
      {links && Object.keys(links).length !== 0 && (
        <>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <h4>Suivez-nous sur les réseaux sociaux!</h4>
          </div>
          <div className="d-flex flex-row justify-content-evenly mx-5">
            {links["facebook"] && (
              <Link to={links["facebook"]}>
                <CButton
                  color="light"
                  size="lg"
                  className="bg-info text-white text-uppercase font-weight-bold"
                  style={{
                    background: "",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Facebook")}
                >
                  <CIcon icon={cibFacebook} /> Facebook
                </CButton>
              </Link>
            )}
            {links["instagram"] && (
              <Link to={links["instagram"]}>
                <CButton
                  color="light"
                  size="lg"
                  className="bg-light text-white text-uppercase font-weight-bold"
                  style={{
                    background:
                      "radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Instagram")}
                >
                  <CIcon icon={cibInstagram} /> Instagram
                </CButton>
              </Link>
            )}
            {links["tiktok"] && (
              <Link to={links["tiktok"]}>
                <CButton
                  color="light"
                  size="lg"
                  className="text-black text-uppercase font-weight-bold"
                  style={{
                    background: "#00eae7",
                    fontWeight: "bold",
                    fontSize: "small",
                    minWidth: 115,
                  }}
                  onClick={track("Tiktok")}
                >
                  <CIcon icon={cibTiktok} />{" "}
                  &nbsp;&nbsp;&nbsp;Tiktok&nbsp;&nbsp;&nbsp;
                </CButton>
              </Link>
            )}
          </div>
        </>
      )}
      <CRow className="d-flex flex-column justify-content-center align-items-center mt-3">
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <h4>Votre avis nous intéresse!</h4>
        </div>
        <StarRating rating={rating} onRatingChange={handleRatingChange} />
        {rating === 0 ? null : (
          <>
            {rating <= 3 ? (
              <CForm
                className="pb-3"
                style={{ backgroundColor: theme.backgroundColor }}
              >
                <CFormLabel htmlFor="exampleFormControlTextarea1">
                  Nous sommes désolés que votre expérience n'ait pas été à la
                  hauteur de vos attentes. Nous serions ravis d'avoir vos
                  impressions et suggestions.
                </CFormLabel>
                <CFormTextarea
                  id="exampleFormControlTextarea1"
                  label="Votre commentaire:"
                  rows={4}
                  value={message}
                  onChange={handleMessageBoxChange}
                ></CFormTextarea>
              </CForm>
            ) : (
              <div className="mt-3">
                <p>
                  Merci! {links["maps"] && (
                    <span>
                      Si vous avez le temps, laissez-nous également un avis sur{" "}
                      <a href={links["maps"]} style={{ color: theme.primaryColor }} onClick={track("gmaps")}>
                        Google Maps
                      </a>
                    </span>
                  )}
                </p>
              </div>
            )}
          </>
        )}
        {rating !== 0 &&
          <div className="d-flex justify-content-center align-items-center">
            <CButton
              className="mt-3"
              style={{
                background: theme.activeColor,
                borderColor: theme.borderColor,
                color: theme.activeTextColor,
              }}
              onClick={submit}
            >
              Envoyer
            </CButton>
          </div>
        }
      </CRow>
      <CToaster ref={toaster} push={toast} placement="bottom" />
    </CContainer>
  );
};

export default React.memo(Rate);
