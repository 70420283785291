const dev = {
    "API_URL": "http://localhost:8080",
    "DEMO_EATERY_ID": "654690507566e50b1e97812b",
}

const prod = {
    "API_URL": "https://api.forknife.app",
    "DEMO_EATERY_ID": "655ed40770dea77d39345032",
}

export const config = process.env.NODE_ENV === 'development' ? dev : prod;