import React from "react";
import ReactGA from 'react-ga';
import { useParams } from "react-router-dom";
import useApiDataStore from "../../useApiDataStore";
import { useEffect } from "react";
import { logPageView } from "../../analytics";
import Start2 from "./Start2";
import Start from "./Start";

const StartScreenPicker = () => {
    const { theme, getEateryInfo } = useApiDataStore();
    const { eateryId } = useParams();

    // Google Analytics
    useEffect(() => {
        logPageView("start", eateryId);
    }, []);

    useEffect(() => {
        const shouldAddFontLink = true;

        if (shouldAddFontLink) {
            let linkElement = document.createElement('link');
            linkElement.rel = 'stylesheet';
            linkElement.href = 'https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,900;1,800&display=swap';

            document.head.appendChild(linkElement);

            return () => {
                document.head.removeChild(linkElement);
            };
        }
    }, []);


    useEffect(() => {
        if (!theme) {
            getEateryInfo(eateryId);
        }
    }, [eateryId]);

    ReactGA.pageview(window.location.pathname + window.location.search);

    if (!theme) {
        return "";
    } else {
        switch (theme.startScreenVersion) {
            case 2: return <Start2 />
            default: return <Start /> 
        }
    }
};

export default React.memo(StartScreenPicker);
