import ReactGA from 'react-ga';

export const initGA = () => {
  ReactGA.initialize('G-Y91BEBJ9ZV', { debug: false });
};

export const logPageView = (name, eateryId, foodItemId = null) => {
  const additionalData = {
    page: window.location.pathname,
    name: name,
    eateryId: eateryId,
    datetime: new Date().toISOString()
  };

  if (foodItemId !== null) {
    additionalData.foodItemId = foodItemId;
  }

  ReactGA.set(additionalData);
  ReactGA.pageview(window.location.pathname);
};

export const trackButtonClick = (buttonName) => {
  ReactGA.event({
    category: "Social Media",
    action: `Clicked ${buttonName} button`,
  });
};