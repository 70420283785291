import { CButton, CCard, CCardBody, CCardTitle, CCol, CContainer, CImage, CRow } from "@coreui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import emptyDoodle from "../../assets/images/order.png";
import useApiDataStore from "../../useApiDataStore";
import OrderItem from "./OrderItem";
import { useEffect } from "react";
import { logPageView } from "../../analytics";

const Order = () => {
    const { theme, info, menu, order } = useApiDataStore();
    const { eateryId } = useParams();

    // Google Analytics
    useEffect(() => {
        logPageView("order", eateryId);
    }, []);

    function findObjectById(data, targetId) {
        for (const category in data) {
            if (data.hasOwnProperty(category)) {
                const items = data[category];
                const foundItem = items.find(item => item.id === targetId);
                if (foundItem) {
                    return foundItem;
                }
            }
        }
        return null;
    }

    function renderOrderItems(order, menu) {
        return order.map((orderItem) => {
            const menuItem = findObjectById(menu, orderItem.id);
            const extrasString = JSON.stringify(orderItem.extras);

            return (
                <OrderItem
                    key={orderItem.id + extrasString}
                    id={orderItem.id}
                    name={menuItem.name}
                    thumbnail={menuItem.thumbnail}
                    price={menuItem.price}
                    currency={info?.currency}
                    extras={extrasString}
                />
            );
        });
    }

    return (
        <CContainer
            xl
            className="g-1 d-flex flex-column"
            style={{ color: theme.textColor, height: '100%', paddingBottom: '10vh', overflowY: "scroll", overflowX: "hidden", boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.8)" }}
        >
            <CRow className="g-0 mt-4 d-flex w-100 justify-content-center align-items-center">
                <h1 className="d-flex w-100 justify-content-center align-items-center">Récapitulatif</h1>
            </CRow>
            <CCol className="my-1 mx-3 mb-2">
                {menu && order.length !== 0 &&
                    <>
                        {renderOrderItems(order, menu)}
                        <CRow xs={{ cols: 1, gutter: 1 }} md={{ cols: 1 }} className="border-bottom" style={{ paddingTop: '10vh', backgroundColor: theme.backgroundColor }}>
                            <CCol>
                                <CCard
                                    className={`mb-1 border-0`}
                                    style={{ backgroundColor: theme.backgroundColor }}
                                >
                                    <CCardBody className="px-0">
                                        <CRow className="w-100 m-0">
                                            <CCol className="g-0" xs={12}>
                                                <CCardTitle className="mb-2 pb-2">
                                                    <span style={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        display: 'flex',
                                                        justifyContent: 'space-between'
                                                    }}>
                                                        <span style={{
                                                            flex: 1,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            whiteSpace: "nowrap",
                                                            color: theme.textColor,
                                                        }}>
                                                            Sous-total
                                                        </span>
                                                        <span className="m-0 p-0" style={{ display: "flex", alignContent: "center", fontSize: 16, color: theme.textColor }}>
                                                            &nbsp;&nbsp;{order.reduce((accumulator, orderItem) => {
                                                                return accumulator + (findObjectById(menu, orderItem.id).price + Object.values(orderItem.extras).reduce((acc, extraPrice) => { return acc + extraPrice }, 0)) * orderItem.quantity;
                                                            }, 0)} {info?.currency}
                                                        </span>
                                                    </span>
                                                </CCardTitle>
                                            </CCol>
                                        </CRow>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </>
                }
                {(!menu || order.length === 0) && (
                    <>
                        <CImage
                            className="my-3"
                            src={emptyDoodle}
                            align="center" style={{ height: '40vh', maxWidth: '100%', objectFit: 'cover' }} />
                        <h4 style={{ textAlign: "center" }}>Vous n'avez encore rien selectionné.<br />Trouvez ce qui vous plait dans le menu !</h4>
                        <Link to="../menu">
                            <CButton
                                color="light"
                                size="lg"
                                className="w-100 bg-light text-black text-uppercase font-weight-bold"
                                style={{ fontWeight: "bold", fontSize: "small" }}
                            >
                                Retour au menu
                            </CButton>
                        </Link>
                    </>
                )}
            </CCol>
        </CContainer>
    );
};

export default React.memo(Order);
