import { cilCheckAlt, cilHandPointUp, cilLockLocked, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardImage,
  CCardTitle,
  CCol,
  CRow
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useApiDataStore from "../../../useApiDataStore";
import ImageThumbnail from "./ImageThumbnail";
import './FoodItemCard.css'

const FoodItemCard = ({ id, isSelected, name, description, price, otherSizes, currency, photo, video, thumbnail, extras, popular, outOfStock, tags }) => {
  const { theme, order, setOrder } = useApiDataStore();
  const [showCardOverlay, setShowCardOverlay] = useState(false);

  useEffect(() => {
    const hasViewedAnimation = localStorage.getItem('hasViewedAnimation');

    // If the animation hasn't been viewed yet, show it
    if (!hasViewedAnimation) {
      setShowCardOverlay(true);
      localStorage.setItem('hasViewedAnimation', 'true');
      setTimeout(() => {
        setShowCardOverlay(false);
      }, 5000);
    }
  }, []);

  const toggleSelection = () => {
    isSelected = !isSelected;
    if (isSelected) {
      addToOrder();
    } else {
      removeFromOrder();
    }
  };
  
  function addToOrder() {
    const itemIndex = order.findIndex(item => {
      if (item.id === id) {
        return Object.keys(item.extras).length === 0;
      }
      return false;
    });

    if (itemIndex === -1) {
      const newItem = {
        id: id,
        quantity: 1,
        extras: {}
      };
      order.push(newItem);
    }
    setOrder([ ...order ]);
  }

  function removeFromOrder() {
    const itemIndex = order.findIndex(item => {
      return item.id === id
    });

    if (itemIndex !== -1) {
      order.splice(itemIndex, 1);
      setOrder([ ...order ]);
    }
  }

  function prettyPrintPrice() {
    const maxPrice = JSON.parse(otherSizes) !== null? Math.max(Object.values(JSON.parse(otherSizes))) : null
    const maxPriceString = maxPrice === null ? "" : `-${maxPrice}`;
    return `${price}${maxPriceString}`
  }

  return (
    <CRow xs={{ cols: 1, gutter: 4 }} md={{ cols: 2 }} style={{ backgroundColor: theme.backgroundColor }}>
      <CCol>
        <CCard
          className={`px-2 py-1 mb-2`}
          style={{ background: `${isSelected ? theme.activeColor : "transparent"}`, boxShadow: "0px -4px 8px rgba(0, 0, 0, 0.2)" }}
        >
          <CRow className="g-0 align-items-center mr-1">
            <CCol xs={thumbnail === null ? 10 : 7} className="g-0">
              <Link
                to={`item/${id}`}
                key={id}
                className="text-decoration-none"
                state={{
                  id: id,
                  name: name,
                  description: description,
                  price: price,
                  photo: photo,
                  video: video,
                  thumbnail: thumbnail,
                  extras,
                  popular: popular,
                  outOfStock: outOfStock,
                  tags: tags,
                }}
              >
                <CCardBody className="px-0">
                  <CCardTitle className="mb-0">
                    <span style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      display: 'block',
                      color: `${isSelected ? theme.activeTextColor : theme.textColor}`,
                    }}>{name}</span>
                  </CCardTitle>

                  <p className="m-0" style={{ display: "flex", alignContent: "center", fontSize: 16, color: `${isSelected ? theme.activeTextColor : theme.textColor}`, }}>
                    {prettyPrintPrice()} {currency || ""}
                    {outOfStock && (<CButton className="bg-danger text-white mx-2 p-1 rounded" style={{
                      fontSize: 10,
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}>
                      Indisponible
                    </CButton>)}
                    {popular && (<CButton className="text-white mx-2 p-1 rounded" style={{
                      fontSize: 10,
                      fontWeight: "bolder",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      backgroundColor: theme.primaryColor,
                    }}>
                      Populaire!
                    </CButton>)}
                  </p>
                  <p
                    className="mb-0"
                    style={{
                      paddingRight: "1rem",
                      fontSize: 14,
                      lineHeight: "18px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                      color: `${isSelected ? theme.activeTextColor : theme.textColor}`
                    }}
                  >
                    {description}
                  </p>
                </CCardBody>
              </Link>
            </CCol>
            {thumbnail !== null && (
              <CCol xs={3} style={{ aspectRatio: "1/1", flexShrink: 0 }}>
                <Link
                  to={`item/${id}`}
                  key={id}
                  className="text-decoration-none"
                  state={{
                    id: id,
                    name: name,
                    description: description,
                    price: price,
                    photo: photo,
                    video: video,
                    thumbnail: thumbnail,
                    extras,
                    popular: popular,
                    outOfStock: outOfStock,
                    tags: tags,
                  }}
                >
                  <CCardImage
                    component={() => <ImageThumbnail src={`data:image/jpeg;base64,${thumbnail}`} alt={name} />}
                  />
                </Link>
              </CCol>
            )}
            <CCol xs={2} className='d-flex justify-content-center align-items-center'>
              <CButton onClick={toggleSelection} size='sm' className={`rounded-circle`} disabled={outOfStock === true}
                style={{
                  background: isSelected ? theme.successColor : "transparent",
                  borderColor: theme.borderColor,
                  color: isSelected ? theme.activeTextColor : theme.textColor
                }}>
                {outOfStock === true && <CIcon icon={cilLockLocked} />}
                {outOfStock !== true && <CIcon icon={isSelected ? cilCheckAlt : cilPlus} />}
              </CButton>
            </CCol>
            <div className={`card-overlay flex-column justify-content-center align-items-center mr-1`} style={{ display: showCardOverlay === true ? 'flex' : 'none', position: "absolute", x: 0, y: 0, color: "white", height: "100%", width: "95%", background: "rgb(0,0,0,0.5)", pointerEvents: "none" }}>
              <CIcon icon={cilHandPointUp} color="white" size="xl" className='zoom-in-out' />
              <p>Plus de détails</p>
            </div>
          </CRow>
        </CCard>
      </CCol>
    </CRow >
  );
};

export default React.memo(FoodItemCard);
