import { CImage } from "@coreui/react";
import React from "react";

const ImageThumbnail = ({ src, alt }) => {

  return (
    <CImage src={src} alt={alt} thumbnail loading="lazy" className="h-100 w-100 object-fit-cover" />
  );
};

export default React.memo(ImageThumbnail);
