import {
    CButton,
    CButtonGroup,
    CCard,
    CCardBody,
    CCardImage,
    CCardTitle,
    CCol,
    CRow,
} from "@coreui/react";
import React from "react";
import useApiDataStore from "../../useApiDataStore";
import ImageThumbnail from "../menu/components/ImageThumbnail";
import CIcon from "@coreui/icons-react";
import { cilTrash } from "@coreui/icons";

const OrderItem = ({ id, name, thumbnail, price, currency, extras }) => {
    const { theme, order, setOrder } = useApiDataStore();
    var orderItemIndex = findOrderItemIndex(id);

    function findOrderItemIndex(id) {
        return order.findIndex((item) => {
            if (item.id === id) {
                const orderExtrasKeys = Object.keys(item.extras);
                const itemExtrasKeys = Object.keys(JSON.parse(extras));
                return (
                    orderExtrasKeys.every((key) => itemExtrasKeys.includes(key)) &&
                    itemExtrasKeys.every((key) => orderExtrasKeys.includes(key))
                );
            }
            return false;
        });
    }


    const plus = () => {
        order[orderItemIndex].quantity = Math.min(
            order[orderItemIndex].quantity + 1,
            10
        );
        setOrder([...order]);
    };
    const minus = () => {
        order[orderItemIndex].quantity = Math.max(
            order[orderItemIndex].quantity - 1,
            0
        );
        if (order[orderItemIndex].quantity === 0) {
            order.splice(orderItemIndex, 1);
            orderItemIndex = findOrderItemIndex(id);
        }
        setOrder([...order]);
    };

    return (
        <CRow
            xs={{ cols: 1, gutter: 1 }}
            md={{ cols: 2 }}
            className="border-bottom"
            style={{ backgroundColor: theme.backgroundColor }}
        >
            <CCol>
                <CCard
                    className={`mb-1 mx-1 px-2 border-0`}
                    style={{
                        backgroundColor: theme.activeColor,
                        color: theme.activeTextColor,
                        boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.3)",
                    }}
                >
                    <CRow>
                        {thumbnail && (
                            <CCol
                                xs={4}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    aspectRatio: "1/1",
                                    flexShrink: 0,
                                }}
                            >
                                <div style={{ aspectRatio: "1/1" }}>
                                    <CCardImage
                                        component={() => (
                                            <ImageThumbnail
                                                src={`data:image/jpeg;base64,${thumbnail}`}
                                                alt={name}
                                            />
                                        )}
                                    />
                                </div>
                            </CCol>
                        )}
                        <CCol xs={!thumbnail ? 12 : 8} className="g-0 align-items-center">
                            <CCol xs={12} className="g-0">
                                <CCardBody className="px-0">
                                    <CRow className="w-100 m-0">
                                        <CCol xs={12}>
                                            <CCardTitle className="mb-2 pb-2">
                                                <span
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        overflow: "hidden",
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <span
                                                        style={{
                                                            flex: 1,
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                            display: "-webkit-box",
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: "vertical",
                                                        }}
                                                    >
                                                        {name}
                                                    </span>
                                                    <span
                                                        className="m-0 p-0"
                                                        style={{
                                                            display: "flex",
                                                            alignContent: "center",
                                                            fontSize: 16,
                                                            color: theme.activeTextColor,
                                                        }}
                                                    >
                                                        &nbsp;&nbsp;{order[orderItemIndex].quantity} x{" "}
                                                        {price} {currency}
                                                    </span>
                                                </span>
                                            </CCardTitle>
                                            <CCardBody className="m-0 p-0">
                                                <CRow>
                                                    <CCol>
                                                        {Object.entries(order[orderItemIndex].extras).map(
                                                            (extra, index) => {
                                                                return (
                                                                    <div key={index} className="d-flex font-size-sm">
                                                                        <span
                                                                            style={{
                                                                                flex: 1,
                                                                                overflow: "hidden",
                                                                                fontSize: 13,
                                                                            }}
                                                                        >
                                                                            +{extra[0]}
                                                                        </span>
                                                                        <span
                                                                            className="m-0 p-0"
                                                                            style={{
                                                                                display: "flex",
                                                                                alignContent: "center",
                                                                                fontSize: 13,
                                                                                color: theme.activeTextColor,
                                                                            }}
                                                                        >
                                                                            &nbsp;&nbsp;
                                                                            {extra[1]} {currency}
                                                                        </span>
                                                                    </div>
                                                                );
                                                            }
                                                        )}
                                                    </CCol>
                                                </CRow>
                                                <CRow
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <CButtonGroup
                                                        role="group"
                                                        className="my-2 d-flex align-items-center"
                                                        style={{ width: "fit-content" }}
                                                    >
                                                        <CButton
                                                            onClick={minus}
                                                            variant="outline"
                                                            style={{
                                                                background: theme.activeColor,
                                                                borderColor: theme.borderColor,
                                                                color: theme.activeTextColor,
                                                            }}
                                                        >
                                                            {order[orderItemIndex].quantity === 1 ? (<CIcon
                                                                icon={cilTrash}
                                                                size="sm"
                                                                style={{ color: theme.activeTextColor }}
                                                            />) : " - "}
                                                        </CButton>
                                                        <CButton
                                                            variant="outline"
                                                            disabled
                                                            style={{
                                                                opacity: "initial",
                                                                background: theme.activeColor,
                                                                borderColor: theme.borderColor,
                                                                color: theme.activeTextColor,
                                                            }}
                                                        >
                                                            {order[orderItemIndex].quantity}
                                                        </CButton>
                                                        <CButton
                                                            onClick={plus}
                                                            variant="outline"
                                                            style={{
                                                                opacity: "initial",
                                                                background: theme.activeColor,
                                                                borderColor: theme.borderColor,
                                                                color: theme.activeTextColor,
                                                            }}
                                                        >
                                                            +
                                                        </CButton>
                                                    </CButtonGroup>
                                                </CRow>
                                            </CCardBody>
                                        </CCol>
                                    </CRow>
                                </CCardBody>
                            </CCol>
                        </CCol>
                    </CRow>
                </CCard>
            </CCol>
        </CRow>
    );
};

export default React.memo(OrderItem);
