import React from "react";
import useApiDataStore from "../../../useApiDataStore";
import FoodItemCard from "./FoodItemCard";
import { useEffect } from "react";
import { CCard, CCardImage, CCardImageOverlay, CCardTitle } from "@coreui/react";

const FoodItemList = ({ menuData, categories, currency, setSelectedCategory }) => {
  const { order, theme } = useApiDataStore();

  const cardImageStyle = {
    width: '100%',
    height: '15vh',
    objectFit: 'cover',
  };

  const gradientStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0))',
  };

  const overlayStyle = {
    display: 'flex',
    //justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
  };

  const textStyle = {
    color: 'white',
    fontFamily: 'DM Serif Display, serif',
    fontWeight: 400,
    fontSize: '36px'
  };

  useEffect(() => {
    const handleScroll = () => {
      const anchors = document.querySelectorAll('.category-section a[id]');
      let closestAnchor = null;
      let closestAnchorDistance = Infinity;

      anchors.forEach((anchor) => {
        const distance = Math.abs(anchor.getBoundingClientRect().top);

        if (distance < closestAnchorDistance) {
          closestAnchor = anchor;
          closestAnchorDistance = distance;
        }
      });

      if (closestAnchor) {
        setSelectedCategory(closestAnchor.id);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setSelectedCategory]);

  const categoriesObject = categories?.reduce((acc, category) => {
    acc[category.tag] = category;
    return acc;
  }, {});

  function findOrderItemIndex(id) {
    return order.findIndex((item) => {
      return item.id === id;
    });
  }

  return (
    <>
      {Object.keys(menuData).map((categoryTag, index) => (
        <div
          className="p-2 category-section"
          data-category={categoryTag}
          key={categoryTag}
          style={{
            color: theme.textColor,
          }}
        >
          <a id={categoryTag}></a>
          <CCard className="w-10 mb-3 bg-dark text-white">
            <CCardImage src={"https://color-hex.org/colors/025253.png"}
              className="w-100"
              style={cardImageStyle}
            />
            <div style={gradientStyle}></div>
            <CCardImageOverlay style={overlayStyle}>
              <CCardTitle style={textStyle}>{categoriesObject[categoryTag]?.displayName}</CCardTitle>
            </CCardImageOverlay>
          </CCard>
          {/* <CCardTitle style={textStyle}>{categoriesObject[categoryTag]?.displayName}</CCardTitle> */}
          {/* <h3 className="mb-3 font-weight-bold d-flex justify-content-center" style={{ marginTop: Object.keys(categoriesObject).indexOf(categoryTag) === 0 ? '1rem' : '0rem' }}>
            {categoriesObject[categoryTag]?.displayName}
          </h3> */}
          {menuData[categoryTag].map((foodItem) => (
            <FoodItemCard
              key={foodItem.id}
              id={foodItem.id}
              name={foodItem.name}
              description={foodItem.description}
              price={foodItem.price}
              otherSizes={JSON.stringify(foodItem.otherSizes)}
              currency={currency}
              photo={foodItem.photo}
              video={foodItem.video}
              thumbnail={foodItem.thumbnail}
              extras={foodItem.extras}
              popular={foodItem.popular}
              outOfStock={foodItem.outOfStock}
              tags={foodItem.tags}
              isSelected={findOrderItemIndex(foodItem.id) !== -1}
            />
          ))}
        </div>
      ))}
    </>
  );
};

export default React.memo(FoodItemList);
