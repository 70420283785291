import { CButton, CCol, CContainer, CImage, CModal, CModalBody, CModalHeader, CModalTitle, CRow } from "@coreui/react";
import forknifeLogo from "../../assets/images/forknife.png";
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { cibGmail, cibWhatsapp } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

const Settings = () => {
    const [modal, showModal] = useState(false)


    return (
        <CContainer
            sm
            className="g-0 d-flex flex-column justify-content-center align-items-center"
            style={{ paddingBottom: '10vh', overflowY: "scroll", overflowX: "hidden" }}
        >
            <CRow className="w-100 mb-5 pb-5 d-flex flex-column justify-content-center align-items-center g-0">
                <CImage src={forknifeLogo} style={{ height: 23.92, width: 117.74 }} />
            </CRow>
            <CRow>
                <h1>Réglages</h1>
            </CRow>
            <CRow className="m-5"></CRow>
            <CRow className="mx-5 my-2 px-4 w-100">
                <CCol style={{ fontWeight: "bold" }}>
                    Langue
                </CCol>
                <CCol className="w-100">
                    <span style={{ fontWeight: "bold" }}>Français</span> | <span>Anglais</span>
                </CCol>
            </CRow>
            <CRow className="mx-5 my-2 px-4 w-100">
                <CCol style={{ fontWeight: "bold" }}>
                    Thème
                </CCol>
                <CCol className="w-100">
                    <span style={{ fontWeight: "bold" }}>Jour</span> | <span>Nuit</span>
                </CCol>
            </CRow>
            <CRow className="mx-5 my-2 px-4 w-100">
                <CCol onClick={() => showModal(!modal)} style={{ fontWeight: "bold" }}>
                    ℹ️ A propos de Forknife
                </CCol>
                <CModal
                    visible={modal}
                    onClose={() => showModal(false)}
                    aria-labelledby="AboutForknifeModal"
                >
                    <CModalHeader onClose={() => showModal(false)}>
                        <CModalTitle id="AboutForknifeLabel">Forknife v0.9</CModalTitle>
                    </CModalHeader>
                    <CModalBody>
                        <p style={{ fontWeight: "bold" }}>Contact</p>
                        <p><CIcon icon={cibWhatsapp}/> <a href="https://wa.me/33661495094">+33 6 61 49 50 94</a></p>
                        <p><CIcon icon={cibGmail}/> <a href="casbah.solutions@gmail.com">casbah.solutions@gmail.com</a></p>
                    </CModalBody>
                    {/* <CModalFooter>
                        <CButton color="secondary" onClick={() => setVisible(false)}>
                            Close
                        </CButton>
                        <CButton color="primary">Save changes</CButton>
                    </CModalFooter> */}
                </CModal>
            </CRow>
            <CRow className="m-5"></CRow>
            <Link to="../menu">
                <CButton
                    color="light"
                    size="lg"
                    className="w-100 bg-light text-black text-uppercase font-weight-bold"
                    style={{ fontWeight: "bold", fontSize: "small" }}
                >
                    Retour au menu
                </CButton>
            </Link>

        </CContainer>
    );
};

export default React.memo(Settings);
